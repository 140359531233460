<template>
  <div>
    <div class="flex align-center">
      <div class="flex justify-between align-center mb-6">
         <h2 class="text-uppercase font-bold">Add New course</h2>
      </div>
      <div class="ml-auto bg-green pointer px-2 py-1 text-white rounded inline-flex align-center">
        <svg class="w-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 32 32" fill="#5B5B5B">
          <path
              d="M16 4C9.382813 4 4 9.382813 4 16C4 22.617188 9.382813 28 16 28C22.617188 28 28 22.617188 28 16C28 9.382813 22.617188 4 16 4 Z M 16 6C21.535156 6 26 10.464844 26 16C26 21.535156 21.535156 26 16 26C10.464844 26 6 21.535156 6 16C6 10.464844 10.464844 6 16 6 Z M 16 10C13.800781 10 12 11.800781 12 14L14 14C14 12.882813 14.882813 12 16 12C17.117188 12 18 12.882813 18 14C18 14.765625 17.507813 15.445313 16.78125 15.6875L16.375 15.8125C15.558594 16.082031 15 16.863281 15 17.71875L15 19L17 19L17 17.71875L17.40625 17.59375C18.945313 17.082031 20 15.621094 20 14C20 11.800781 18.199219 10 16 10 Z M 15 20L15 22L17 22L17 20Z"
              fill="#fff"/>
        </svg>
        <span class="text-base ml-1"> Help </span>
      </div>
    </div>
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-20">

        <ul>
          <div  v-for="(menu, index) in menus" :key="index">
          <li class="py-1" v-if="menu.visibility">
            
            <router-link
                class="text-gray-700"
                event=""
                :to="{name: menu.path}"
                @click.native.prevent="handleOpenMenu(menu)">
                {{ menu.name }}
                <span v-if="menu.status" class="text-primary text-lg font-bold"> &check; </span>
            </router-link>
            
          </li>
          </div>
        </ul>
      </div>
      <div class="md-layout-item md-size-80">
        <!-- <div class="mb-6"> -->
          <!-- <h2 class="mb-4 text-uppercase text-xl">{{title}} </h2> -->
          <!-- <ul class="progress-bar">
            <li :class="menu.status ? 'is-completed' : '' " v-for="(menu, index) in menus" :key="index"></li>
          </ul>
        </div> -->

        <router-view :menus="menus" @menu="menuClick" @clear-all="clearAll"></router-view>
      </div>

    </div>
    <div class="md-layout mt-8">
      <div class="md-layout-item flex justify-center align-center">
        <div>
          <p class="text-base font-semibold">
            <span class="opacity-75">Copyright&copy; all rights reserved by </span>
            <span>APICL</span>
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  data() {
    return {
      title: 'Basic Info',
      menus: [
              {id: 1, name: 'Basic Information', status: false, path: 'courseCreation.basicInformation', visibility: true},
              {id: 2, name: 'Course Type', status: false, path: 'courseCreation.courseType', visibility: true},
              {id: 3, name: 'AdOns', status: false, path: 'courseCreation.adons' , visibility: true},
              {id: 4, name: 'Properties', status: false, path: 'courseCreation.properties', visibility: true},
              {id: 5, name: 'PTE Practice', status: false, path: 'courseCreation.properties.ptePractice', visibility: false},
              {id: 6, name: 'Practice Test', status: false, path: 'courseCreation.properties.practiceTest', visibility: false},
              {id: 7, name: 'Mock Test', status: false, path: 'courseCreation.properties.mockTest', visibility: false},
              {id: 8, name: 'Quiz', status: false, path: 'courseCreation.properties.quiz', visibility: false},
              {id: 9, name: 'Materials', status: false, path: 'courseCreation.properties.materials', visibility: false},
              {id: 10, name: 'Live Class', status: false, path: 'courseCreation.properties.liveClass', visibility: false},
              {id: 11, name: 'Webinars', status: false, path: 'courseCreation.properties.webinars', visibility: false},
              {id: 12, name: 'One To One Appointment', status: false, path: 'courseCreation.properties.OneToOneAppointment', visibility: false},
              {id: 13, name: 'Duration & Price', status: false, path: 'courseCreation.durationAndPrice', visibility: true},
              {id: 14, name: 'Review', status: false, path: 'courseCreation.review', visibility: true},
              {id: 15, name: 'Preview', status: false, path: 'courseCreation.preview', visibility: true},
            ],
    }
  },

  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),

    //handle open menu check if route status tre or false
    handleOpenMenu(route) {
      
      if (route.status == true) {
        //if job application success true then menus status updated to false and return Basic info page
        if (this.getLocal("course-create-info-success") == true) {
          this.menus.map((menu) => {
            menu.status = false;
          });
          this.$router.push({ name: "courseCreation.basicInformation"});
        }else{
          this.$router.push({ name: route.path });
        }
      } else {
        if (this.$route.name != route.path) {
          this.setShowSnackbar("Please complete " + route.name + " form first");
          setTimeout(() => {
            this.dialog(false);
          }, 1000);
        }
      }
    },

    menuClick(value) {

     let menuValues = value.split("#");
      let status = true;
      if(menuValues.length > 1) status = false;
      let packageInfo = this.getLocal("course-create-info"); 
      this.menus.map((menu, index) => {

        if(packageInfo && packageInfo.package_detail){
          menu.visibility = this.propertiesMenus(packageInfo, menu.path); 
        }
        if (menu.path == menuValues[0]) {
          menu.status = status;
          this.title = this.menus[index+1].name;
        }
      });
      this.setLocal('course-create-menu', this.menus);
    },

    propertiesMenus(courseInfo, path){
    
        if(path=='courseCreation.properties.ptePractice') return courseInfo.package_detail.is_pte_practice;
        else if(path=='courseCreation.properties.practiceTest') return courseInfo.package_detail.is_practice_test;
        else if(path=='courseCreation.properties.mockTest') return courseInfo.package_detail.is_mock_test;
        else if(path=='courseCreation.properties.quiz') return courseInfo.package_detail.is_quiz;
        else if(path=='courseCreation.properties.materials') return courseInfo.package_detail.is_materials;
        else if(path=='courseCreation.properties.liveClass') return courseInfo.package_detail.is_live_class;
        else if(path=='courseCreation.properties.webinars') return courseInfo.package_detail.is_webinars;
        else if(path=='courseCreation.properties.OneToOneAppointment') return courseInfo.package_detail.is_one_to_one_appointment;
        else return true;
      
    },

    clearAll() {
      this.remove("course-create-info");
      if (this.$route.name != "courseCreation.basicInformation") {
        this.$router.push({ name: 'courseCreation.basicInformation' });
      }
    },
  },
  created() {
    // this.clearAll();
    let menus = this.getLocal("course-create-menu"); 
    if(menus){
      this.menus = menus;
    }
  }
}
</script>